import React from "react";
import Navbars from "routers/navbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faShieldHalved
} from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { 
    Button, 
    Col, 
    FormGroup, 
    Input, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    Row,
    Spinner
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import promptpay from 'assets/img/Payment/prompt-pay.png';
import { LoginContext } from "components/loginContext/LoginContext";
import axios from "axios";
import Swal from "sweetalert2";
import { PROVINCE_LIST } from "./components/province";
import LoginModal from "components/loginModal";

const path_url = process.env.REACT_APP_API_CONNECT;

function Payments(){
    const [active, setActive] = React.useState(2);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const history = useHistory();
    // let { id } = useParams();
    const [cardInfo, setCardInfo] = React.useState({
        number: "",
        name: "",
        exp: {
            month: "",
            year: ""
        },
        cvv: "",
        city: "",
        postalCode: ""
    })
    const { userData } = React.useContext(LoginContext);
    const [user, setUser] = React.useState(null);
    const [promptpayModal, setPromptpayModal] = React.useState(false);
    const [promptpayResult, setPromptpayResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [types, setTypes] = React.useState("monthly")

    React.useEffect(() =>{
        if(userData){
            setUser(userData)
        }
        const user = localStorage.getItem('profile')
        if(!user){
            setLoginOpen(true)
        }
    },[userData])

    function cc_format(value) {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = [];
        var len = match.length;
        for (var i=0; i<len; i+=4) {
          parts.push(match.substring(i, i+4))
        }
        if (parts.length) {
          return parts.join(' ')
        } else {
          return value
        }
    }

    async function _onSubmit(){
        setLoading(true)
        try{
            if(active === 1){
                const date = new Date()
                date.setDate(date.getDate() + types === "monthly" ? 31 : 366)
                const result = await axios.post(path_url+'/payment/payment_checkout',{
                    email: user.email, 
                    name: cardInfo.name, 
                    amount: types === "monthly" ? 450 : 5400, 
                    exp : {
                        month: cardInfo.exp.month,
                        year: cardInfo.exp.year
                    }, 
                    cardNumber: (cardInfo.number).replaceAll(" ", ""), 
                    cvv: cardInfo.cvv, 
                    userID: user._id, 
                    expired: date,
                    city: cardInfo.city,
                    postalCode: cardInfo.postalCode
                })
                if(result){
                    SuccessFull()
                    setLoading(false)
                }
            }else{
                const result = await axios.post(path_url+'/payment/createPromptpay',{
                    amount: types === "monthly" ? 450 : 5400,
                    user: {
                        _id: user._id,
                        name: user.name,
                        email: user.email
                    }
                })
                if(result.status === 200){
                    setPromptpayResult(result.data)
                    setPromptpayModal(true)
                    setLoading(false)
                }
            }
        }catch(e){
            console.log(e)
            setLoading(false)
        }
    }

    function _onSuccess(){
        setPromptpayResult(null)
        setPromptpayModal(false)
        setTimeout(() => {
            history.push('/dashboard')
        }, 500);
    }

    function expDate(exp){
        return new Date(exp).toLocaleString('th', {day:'2-digit', month: 'short', year:'numeric', hour:'2-digit', minute:'2-digit'})
    }

    function onSelectProvince(val){
        const province = PROVINCE_LIST.find(element =>{
            return element.province_id === val
        });
        setCardInfo({...cardInfo, city: province.province_name_eng})
    }

    function SuccessFull(){
        Swal.fire({
            icon: 'success',
            title: "สำเร็จ!",
            text: "การชำระเงิน สำเร็จแล้ว!",
            timer: 2000,
            showConfirmButton: true,
            confirmButtonColor: '#EF9751',
            confirmButtonText: 'เยี่ยมเลย!'
        })
        .then((result) =>{
            if(result.isConfirmed){
                history.push('/dashboard')
            }
        })
    }

    return(
        <>
        <Navbars />
        <div className="payment-containner">
            <div className="payment-header mb-20">
                <Button color="transparent" style={{padding: 10}} className="mr-20" onClick={() => history.goBack()} >
                    <FontAwesomeIcon icon={faChevronLeft} style={{fontSize: 30}} />
                </Button>
                ชำระค่าบริการ
            </div>
            <div className="payment-body">
                <div className="payment-body-wrapper-1">
                    <h4>เลือกวิธีการชำระ</h4>
                    <Row className="mb-20">
                        {/* <Col className="col-12 col-md-6 col-lg-6">
                            <button
                            className={`btn-payment mb-10 ${active === 1 ? "choose" : ""}`}
                            onClick={() => setActive(1)}
                            >
                                <div>
                                    <img alt="visa" src={visa} style={{width: 35, marginRight: 5}} />
                                    <img alt="mastercard" src={master} style={{width: 35}} />
                                </div>
                                บัตรเครดิต/เดบิต
                            </button>
                        </Col> */}
                        <Col className="col-12 col-md-6 col-lg-6">
                            <button
                            className={`btn-payment mb-10 ${active === 2 ? "choose" : ""}`}
                            onClick={() => setActive(2)}
                            >
                                <div>
                                    <img alt="visa" src={promptpay} style={{width: 70, marginRight: 5}} />
                                </div>
                                พร้อมเพย์
                            </button>
                        </Col>
                    </Row>
                    {active === 1 ? 
                    <Row className="mb-20 payment-info">
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>หมายเลขบัตร</Label>
                                <input 
                                className="form-control-2"
                                placeholder="0000 0000 0000 0000"
                                value={cc_format(cardInfo.number)}
                                onChange={(val) => setCardInfo({...cardInfo, number: val.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-12 col-lg-12">
                            <FormGroup className="form-group">
                                <Label>ชื่อบนบัตร</Label>
                                <input 
                                className="form-control-2"
                                placeholder="NAME ON CARD"
                                value={cardInfo.name}
                                onChange={(val) => setCardInfo({...cardInfo, name: val.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label>จังหวัด</Label>
                                <Input 
                                type="select" 
                                className="form-control-2"
                                onChange={(val) => onSelectProvince(val.target.value)}
                                >
                                    <option value="">เลือก</option>
                                    {PROVINCE_LIST.map((item, i) =>{
                                        return(
                                            <option key={i} value={item.province_id}>
                                                {item.province_name}
                                            </option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label>รหัสไปรษณีย์</Label>
                                <input
                                className="form-control-2"
                                value={cardInfo.postalCode}
                                onChange={(val) => setCardInfo({...cardInfo, postalCode: val.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label>รหัส CVV</Label>
                                <input 
                                className="form-control-2"
                                placeholder="CVV CODE"
                                maxLength={3}
                                value={cardInfo.cvv}
                                onChange={(val) => setCardInfo({...cardInfo, cvv: val.target.value})}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-6 col-lg-6">
                            <FormGroup className="form-group">
                                <Label>วันหมดอายุบัตร</Label>
                                <Row>
                                    <Col className="col-3">
                                    <input 
                                    className="form-control-2"
                                    placeholder="MM"
                                    maxLength={2}
                                    pattern="[0-9]*"
                                    value={cardInfo.exp.month}
                                    onChange={(val) => setCardInfo({...cardInfo, exp:{...cardInfo.exp, month: val.target.value}})}
                                    />
                                    </Col>
                                    /
                                    <Col className="col-4">
                                    <input 
                                    className="form-control-2"
                                    placeholder="YYYY"
                                    maxLength={4}
                                    pattern="[0-9]*"
                                    value={cardInfo.exp.year}
                                    onChange={(val) => setCardInfo({...cardInfo, exp:{...cardInfo.exp, year: val.target.value}})}
                                    />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    : 
                    <Row>
                        <Col className="col-12">
                            <h5>วิธีการชำระ พร้อมเพย์</h5>
                            <p>{"-> เลือกการชำระแบบ 'พร้อมเพย์'"}</p>
                            <p>{"-> คลิกปุ่ม 'ชำระเงิน'"}</p>
                            <p>{"-> แสกน QR Code"}</p>
                            <p>{"-> คลิกปุ่ม 'สำเร็จ'"}</p>
                            <p className="mb-10">{"-> รอการตรวจสอบ 5-10 นาที"}</p>
                            <p>หรือ ดาวน์โหลด QR Code</p>
                            <p>{"-> เลือกการชำระแบบ 'พร้อมเพย์'"}</p>
                            <p>{"-> คลิกปุ่ม 'ชำระเงิน'"}</p>
                            <p>{"-> คลิกปุ่ม 'ดาวน์โหลด QR Code'"}</p>
                            <p>{"-> คลิกปุ่ม 'สำเร็จ'"}</p>
                            <p>{"-> เปิดแอพฯ (Mobile Banking)"}</p>
                            <p>{"-> แสกน QR Code และ เลือกจากรูปภาพ"}</p>
                            <p className="mb-10">{"-> รอการตรวจสอบ 5-10 นาที"}</p>
                        </Col>
                    </Row>
                    }
                </div>
                <div className="payment-body-wrapper-2">
                    <h4>สรุปคำสั่งซื้อ</h4>
                    <div className="mb-20">
                        <Row className="mb-10">
                            <Col className="col-6">
                                <FormGroup check onClick={() => setTypes('monthly')} >
                                    <Input type="radio" name="radio1" checked={types === "monthly"} />
                                    <Label check>รายเดือน 450.-/เดือน</Label>
                                </FormGroup>
                            </Col>
                            <Col className="col-6">
                                <FormGroup check onClick={() => setTypes('yearly')}>
                                    <Input type="radio" name="radio1" checked={types === "yearly"} />
                                    <Label check>รายปี 5,400.-/ปี </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* {types === "yearly" ? <p>เดือนละ 350 บาท <label style={{fontWeight: 500}} className="color-default">คุ้มกว่า</label></p> : null} */}
                    </div>
                    <p className="color-gray mb-20">
                        Package : {types === "monthly" ? <label className="color-black">Premium (450 บาท / เดือน)</label> : <label className="color-black">Premium (4,200 บาท / ปี) <p>(ตกเดือนละ 350 บาท <u style={{fontWeight: 500}} className="color-default">ถูกกว่า</u>)</p></label>}
                    </p>
                    <hr className="mb-20" />
                    <div className="color-gray mb-20" style={{display:'flex', justifyContent:'space-between'}}>
                        คำสั่งซื้อ : <label className="color-black">{types === "monthly" ? "450" : "5,400"} บาท</label>
                    </div>
                    <div className="color-gray mb-20" style={{display:'flex', justifyContent:'space-between'}}>
                        รวม : <label className="color-black">{types === "monthly" ? "450" : "5,400"} บาท</label>
                    </div>
                    <hr className="mb-20" />
                    <div className="bg_sky mb-20 payment-service">
                        <div className="wrapper mb-10">
                            <FontAwesomeIcon icon={faShieldHalved} style={{fontSize: 25}} className="mr-20" />
                            <small>ข้อมูลบัตรเครดิต/เดบิตของท่านได้รับความปลอดภัยด้วยการเข้ารหัสที่ปลอดภัย</small>
                        </div>
                        <hr className="mb-10 color-sky" />
                        <div className="wrapper mb-10">
                            <FontAwesomeIcon icon={faCreditCard} style={{fontSize: 25}} className="mr-20" />
                            <small>เพื่อความปลอดภัย จะไม่มีการบันทึกข้อมูลบัตรเครดิต/เดบิต ในระบบ ท่านต้องชำระเองทุกครั้งเมื่อครบระยะเวลา</small>
                        </div>
                    </div>
                    <div className="mb-20">
                        {loading ? 
                        <Button color="sky" className="full-w">
                            <Spinner size='sm' className="mr-10" /> Loading...
                        </Button>
                        : 
                        <Button color="sky" className="full-w" onClick={_onSubmit}>
                            ชำระเงิน
                        </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <LoginModal isOpen={loginOpen} onClose={() => setLoginOpen(false)} />
        <Modal isOpen={promptpayModal} >
            <ModalBody>
                {promptpayResult ? 
                <>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img 
                    alt={promptpayResult.source.scannable_code.image.filename} 
                    src={promptpayResult.source.scannable_code.image.download_uri} 
                    style={{width: '80%'}} 
                    />
                </div>
                <div className="mb-20">
                    QR Code จะหมดอายุใน : {expDate(promptpayResult.expires_at)}
                </div>
                <div>
                    <a href={promptpayResult.source.scannable_code.image.download_uri} className="btn btn-blue" download="TLTD-Promptpay">
                        ดาวน์โหลด QR Code
                    </a>
                </div>
                </>
                : null}
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={_onSuccess}>
                    สำเร็จ
                </Button>
            </ModalFooter>
        </Modal>
        </>
    )
}
export default Payments